<template>
  <section>
    <div class="banner">
      <div
        class="bannerImage"
        :style="{
          'background-image':
            'url(' + require('../assets/img/banner/energy.jpg') + ')',
        }"
      >
        <div class="containerLarge p-4 p-sm-3 p-md-3 p-lg-4 p-xl-4">
          <div
            class="bannerImage-card p-2 p-sm-3 p-md-3 p-lg-4 p-xl-5 
            text-center col-md-8 offset-md-2">
            <h1>Energy</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="cardList mt-4 mt-md-5 mt-lg-5 pt-lg-3">
      <div class="containerLarge">
        <div class="row">
          <div class="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 mb-3 mb-md-5">
            <p class="lead text-center">
              Energy efficiency is essential in Alfa Laval’s production processes.
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
            <a class="nav-link" href="https://productguide.alfalaval.com/" target="_blank" rel="noopener">
              <div class="cardImage">
                <div
                  class="cardBox"
                  :style="{
                    'background-image':
                      'url(' +
                      require('../assets/img/banner/application.jpg') +
                      ')',
                  }"
                ></div>
                <div class="cardContainer">
                  <div class="cardGrandient"></div>
                  <div class="cardContent">
                    <h2 class="cardContent-title">Application</h2>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
            <div class="cardImage" @click="toGlobal('/energy-contacts', 'energy')">
              <div
                class="cardBox"
                :style="{
                  'background-image':
                    'url(' + require('../assets/img/banner/contacts.jpg') + ')',
                }"
              ></div>
              <div class="cardContainer" >
                <div class="cardGrandient"></div>
                <div class="cardContent">
                  <h2 class="cardContent-title">Contacts</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeEnergy',

  data: () => ({
  }),

  computed: {
    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    }
  },

  methods: {
    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },
  }
}; 
</script>
